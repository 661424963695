body {
	@apply bg-background font-[Poppins] text-black;
}

input,
select,
button {
	@apply font-[Poppins] outline-none;
}

.container {
	@apply mx-auto w-full !max-w-[1536px] px-10;
}
.app-input {
	@apply block w-full rounded-md border-0 bg-white py-2 px-5 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600;
}
.btn-primary {
	@apply rounded-md bg-primary py-2 px-5 text-sm text-white;
}
.input-label {
	@apply mb-0.5 block text-sm font-medium leading-6 text-gray-900;
}
